import React, {Dispatch, SetStateAction} from 'react';
import styles from './styles.module.scss';

export interface IChecklistSwitch {
    name:string;
    id:number;
    isSelected:boolean;
}

interface IProps {
    checklists: IChecklistSwitch[];
    setChecklists: Dispatch<SetStateAction<IChecklistSwitch[]>>;
}

const ChecklistSwitch = ({checklists, setChecklists}:IProps) => {

    const handleSelect = (e:React.MouseEvent<HTMLButtonElement>) => {
        const id = Number(e.currentTarget.id);

        setChecklists((prev) => {
            return prev.map((item) => ({
                ...item,
                isSelected: item.id === id
            }))
        })
    };


    return (
        <div className={styles.switch}>
            {checklists.map((item:IChecklistSwitch) => (
                <button
                    key={item.id}
                    id={String(item.id)}
                    onClick={handleSelect}
                    className={item.isSelected ? styles.switch__item_active : styles.switch__item}
                >
                    {item.name}
                </button>
            ))}
        </div>
    );
};

export default ChecklistSwitch;
