import React, {useEffect, useRef, useState} from 'react';
import BaseLayout from "../../layouts/BaseLayout";
import DateFastFilter, {EnumDateFastFilterType} from "../AutomaticAssessmentPage/components/DateFastFilter";
import {useSearchParams} from "react-router-dom";
import CustomPagination from "../AutomaticAssessmentPage/components/Pagination";
import PerPageDropDown from "../AutomaticAssessmentPage/components/PerPageDropDown";
import CustomDatePicker, {TDatePickerItem} from "../../components/CustomDatePicker";
import {IDateFilter} from "../AutomaticAssessmentPage";
import DateService from "../../services/DateService";
import ChecklistTable from "./components/ChecklistTable";
import CompanyObserver from "../../mobx/company/CompanyObserver";
import axiosInstance from "../../services/api/axiosInstance";
import UrlCreator from "../../services/api/UrlCreator";
import {ICallChecklistResource, mapCallChecklist} from "../../resources/ICallChecklist";
import dayjs from "dayjs";
import {EnumSortType} from "../AutomaticAssessmentPage/components/HeadFilter";
import {observer} from "mobx-react-lite";
import ModalFilterOperator, {IFilterSelect} from "../AutomaticAssessmentPage/components/ModalFilterOperator";
import {EnumCallStatus} from "../../resources/ICall.resource";
import FilterTag from "../AutomaticAssessmentPage/components/FilterTag";
import ClearAllTagsBtn from "../AutomaticAssessmentPage/components/ClearAllTagsBtn";
import InputSearch from "../AutomaticAssessmentPage/components/InputSearch";
import styles from './styles.module.scss';
import LoadCallsFile from "../AutomaticAssessmentPage/components/LoadCallsFile";
import FetchService from "../../services/api/FetchService";
import {ChecklistResource} from "../../resources/IChecklist.resource";
import ChecklistSwitch, {IChecklistSwitch} from "./components/ChecklistSwitch";
import {all} from "axios";
import Loader from "../../components/Loader";

export enum EnumChecklistTypes {
    perPage = 'perPage',
    page = 'page',
    phoneNumber = 'phoneNumber',
    startDate = 'startDate',
    endDate = 'endDate',
    dateFilterType = 'dateFilterType',
    yesNo='yesNo'
}

const initialDateFilters = [
    {
        type: EnumDateFastFilterType.LAST_30_DAYS,
        isActive:true
    },
    {
        type: EnumDateFastFilterType.TODAY,
        isActive: false
    },
    {
        type: EnumDateFastFilterType.YESTERDAY,
        isActive:false
    },
    {
        type: EnumDateFastFilterType.CURRENT_WEEK,
        isActive:false
    },
    {
        type: EnumDateFastFilterType.CURRENT_MONTH,
        isActive:false
    },
    {
        type: EnumDateFastFilterType.CUSTOM_RANGE,
        isActive:false
    }
];

const ChecklistPage = () => {
    const [total, setTotal] = useState<number>(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [checklist, setChecklist] = useState<ICallChecklistResource[]>([]);
    const [allChecklist, setAllChecklist] = useState<IChecklistSwitch[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenOperatorsModal, setIsOpenOperatorsModal] = useState<boolean>(false);
    console.log('allChecklist', allChecklist);
    const tableRef= useRef<HTMLDivElement>(null);

    const handleCloseFilterModal = () => setIsOpenOperatorsModal(false)
    const handleOpenFilterModal = () => setIsOpenOperatorsModal(true)

    const getSearchParams = (key: string) => searchParams.get(key);

    const handleSetPage = (page: number) => {
        setSearchParams((prev) => {
            prev.set('page', String(page))
            return prev;
        })
    };

    const handleSetSearchParams = (perPage: number) => {
        setSearchParams((prev) => {
            prev.set('perPage', String(perPage))
            prev.set('page', '1')
            return prev;
        });
    };

    const handleSelectFastDateFilter = (start:string, end:string, type:EnumDateFastFilterType) => {

        setSearchParams((prev) => {
            prev.delete('phoneNumber');
            prev.set('startDate', start);
            prev.set('endDate', end);
            prev.set('dateFilterType', type);
            prev.set('page', '1');
            return prev;
        })
    }

    const getSearchParamsCustomRangeDate = (key: string) => {
        if(searchParams.get('dateFilterType') !== EnumDateFastFilterType.CUSTOM_RANGE) return null;
        const value = searchParams.get(key);
        if(!value) return null;
        if('null' === value) return null;
        return new Date(value);
    }

    const handleSelectDate = (date:[TDatePickerItem, TDatePickerItem]) => {

        setSearchParams((prev) => {
            prev.delete('phoneNumber');
            if(!date[0] && !date[1]){
                prev.set('dateFilterType', EnumDateFastFilterType.TODAY);
                prev.set('startDate', DateService.formatUI(String(new Date()), 'YYYY-MM-DD'));
                prev.set('endDate', DateService.formatUI(String(new Date()), 'YYYY-MM-DD'));
                return prev;
            }

            prev.set('startDate', DateService.formatUI(String(date[0]), 'YYYY-MM-DD'));
            prev.set('endDate', date[1] ? DateService.formatUI(String(date[1]), 'YYYY-MM-DD') : 'null');
            prev.set('dateFilterType', EnumDateFastFilterType.CUSTOM_RANGE);
            return prev;
        });
    }

    const handleSetOperators = (filter: IFilterSelect) => {
        setSearchParams((prev) => {
            const operator = prev.get('operator');

            if(operator){
                const operators = JSON.parse(operator) as IFilterSelect[];

                if(operators.find((item) => item.value === filter.value)){
                    const removeSelectedOperator = operators.filter((item) => item.value !== filter.value);

                    if(removeSelectedOperator.length === 0){
                        prev.delete('operator')
                        return prev;
                    }

                    prev.set('operator', JSON.stringify(removeSelectedOperator))
                    return prev;
                }

                operators.push(filter);
                prev.set('operator', JSON.stringify(operators))
                return prev;
            }

            prev.set('operator', JSON.stringify([filter]))
            return prev;
        })
    };

    const handleClearOperators = () => {
        setSearchParams((prev) => {
            prev.delete('operator')
            return prev;
        })
    };

    const handleSelectSort =(value: string) => {
        setSearchParams((prev) => {
            if(value === '_') {
                prev.delete('sort');
                return prev;
            }

            prev.set('sort', value);
            return prev;
        });
    };

    const getSortQuery = () => {
        const searchQuery = getSearchParams('sort');
        if(!searchQuery) return 'phoneCall.callTime desc';

        return `${searchQuery.split('_')[0]} ${searchQuery.split('_')[1]}`
    };

    const paramsFactory = () => {
        const startDate = DateService.transformPicker(getSearchParams('startDate'));
        const endDate = getSearchParams('endDate');
        if(!startDate ) return;

        const perPage = getSearchParams(EnumChecklistTypes.perPage);
        const page = getSearchParams(EnumChecklistTypes.page);
        const yesNoFilter = () => {
            const yesNo =  getSearchParams(EnumChecklistTypes.yesNo);
            if(!yesNo) return null;

            return `${yesNo.split('_')[0]} eq ${yesNo.split('_')[1]}`;
        };

        const parseOperators = () => {
            const operatorFilter = getSearchParams('operator');
            if(!operatorFilter) return '';
            const operators = JSON.parse(operatorFilter) as IFilterSelect[];
            const operatorIds = operators.map((unit) => Number(unit.value));

            return operatorIds.length ? `phoneCall.employee.id in [${operatorIds}] and` : '';
        }

        const parseSearch = () => {
            const value = getSearchParams('search');
            if(!value) return null;

            if(value.match(`^[0-9]*$`)) {
                return `phoneCall.phoneNumber cp '*${value}*'`;
            } else {
                return `phoneCall.employee.user.fullName lp '*${value.toLowerCase()}*'`
            }
        }

        const endDateMiddleware = () => {
            if(getSearchParams('startDate') === endDate) {
                return DateService.transformPicker(dayjs(startDate).add(2, "day").format('YYYY-MM-DD'));
            }
            return !endDate || endDate === 'null' ? DateService.transformPicker(dayjs(startDate).add(1, "days").format('YYYY-MM-DD')) : DateService.transformPicker(endDate);
        }

        const params = new URLSearchParams();

        params.set('pageSize', String(perPage));
        params.set('pageToken', String(Number(page) - 1));

        switch (true) {
            case Boolean(parseSearch()):
                params.set('searchRequest', `${parseSearch()} and phoneCall.callTime ge 'T${startDate}' and phoneCall.callTime lt 'T${endDateMiddleware()}'`);
                params.set('orderByRequest', `${getSortQuery()}`);
                return params;
            case Boolean(yesNoFilter()):
                params.set('searchRequest', `${parseOperators()} ${yesNoFilter()} and phoneCall.callTime ge 'T${startDate}' and phoneCall.callTime lt 'T${endDateMiddleware()}'`);
                params.set('orderByRequest', `${getSortQuery()}`);
                return params;
            default:
                params.set('searchRequest', `${parseOperators()} phoneCall.callTime ge 'T${startDate}' and phoneCall.callTime lt 'T${endDateMiddleware()}'`);
                params.set('orderByRequest', `${getSortQuery()}`);
                return params;
        }
    };

    const getTags = () => {
        const tags = [];
        const search = searchParams.get('search');
        const operator= getSearchParams('operator');
        const yesNo= getSearchParams(EnumChecklistTypes.yesNo);
        const yesNoTitle =  checklist[0]?.columns?.find((item) => item.columnName === yesNo?.split('_')[0])?.data.question;

        if(yesNo){
            tags.push({
                title: yesNo.split('_')[1] === '0' ? `${yesNoTitle ? yesNoTitle : 'Запитання не знайдено'}=Ні` : `${yesNoTitle ? yesNoTitle : 'Запитання не знайдено'}=Так`,
                type: EnumChecklistTypes.yesNo
            })
        }

        if(operator) {
            const operatorList= JSON.parse(operator) as IFilterSelect[];
            const operatorTags = operatorList.map((item) => ({
                title: item.label,
                type: `operator_${item.value}`
            }));
            tags.push(...operatorTags);
        }

        if(search) {
            tags.push({
                title: search,
                type: 'search'
            })
        }

        return tags;
    };

    const handleClearTag = (type:string) => {

        setSearchParams((prev) => {
            if(type.split('_')[0] === 'operator') {
                const operators = prev.get('operator');
                if(!operators) return prev;

                const parsedOperators = JSON.parse(operators) as IFilterSelect[];
                const filteredOperators = parsedOperators.filter((item:IFilterSelect) => item.value !== type.split('_')[1]);
                if(filteredOperators.length === 0){
                    prev.delete('operator');
                    return prev;
                }
                prev.set('operator', JSON.stringify(filteredOperators));
                return prev;
            }
            if(type.split('-')[0] === 'status') {
                const statuses = prev.get('status');
                if(!statuses) return prev;

                const parsedStatuses = JSON.parse(statuses) as EnumCallStatus[];
                const filteredStatuses = parsedStatuses.filter((item:EnumCallStatus) => item !== type.split('-')[1]);

                if(filteredStatuses.length === 0){
                    prev.delete('status');
                    return prev;
                }
                prev.set('status', JSON.stringify(filteredStatuses));
                return prev;
            }

            prev.delete(type);
            return prev;
        })
    }

    const handleClearAllTags = () => {
        setSearchParams((prev) => {
            prev.delete('phoneNumber');
            prev.delete('search');
            prev.delete('operator');
            prev.delete(EnumChecklistTypes.yesNo);
            return prev;
        })
    }

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchParams((prev) => {

            prev.delete('phoneNumber');
            prev.delete('problemsResult');
            prev.delete('topProblems');
            prev.set('search', value);
            if(!value) prev.delete('search');
            return prev;
        });
    };

    const loadChecklist = async () => {
        const companyId = CompanyObserver.companyId;
        if(!companyId) return;

        const checklistId = allChecklist.find((item) => item.isSelected)?.id;

        try {
            setIsLoading(true);
            const {data} = await axiosInstance.get(UrlCreator.getChecklist(companyId, checklistId),{
                params: paramsFactory()
            });
            setChecklist(mapCallChecklist(data.calls));
            setTotal(data.total);
        } finally {
            setIsLoading(false);
        }
    };

    const loadAllChecklist = async () => {
        const companyId = CompanyObserver.companyId;
        if(!companyId) return;

        try{
            setIsLoading(true);

            const checklists = await FetchService.getAllChecklists(companyId);
            setAllChecklist(checklists.data.map((item, index) => ({
                ...item,
                isSelected: index === 0,// first element selected
            })));
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(!getSearchParams('page') || !getSearchParams('perPage') || !getSearchParams('dateFilterType')) {
            setSearchParams((prev) => {
                prev.set('page', '1');
                prev.set('perPage', '50');
                prev.set('dateFilterType', EnumDateFastFilterType.LAST_30_DAYS);
                prev.set('startDate', DateService.getLast30Days().start);
                prev.set('endDate', DateService.getLast30Days().end);
                return prev;
            });
        }
    }, []);

    useEffect(() => {
        if(!getSearchParams('page') || !getSearchParams('perPage') || !getSearchParams('dateFilterType')) {
            setSearchParams((prev) => {
                prev.set('page', '1');
                prev.set('perPage', '50');
                prev.set('dateFilterType', EnumDateFastFilterType.LAST_30_DAYS);
                prev.set('startDate', DateService.getLast30Days().start);
                prev.set('endDate', DateService.getLast30Days().end);
                return prev;
            });
        }
    }, [searchParams]);

    useEffect(() => {
        loadChecklist();
        loadAllChecklist();
    },[]);

    useEffect(() => {
        loadChecklist()
    },[searchParams, CompanyObserver.companyId, allChecklist]);

    const handleLoadCSV = () => {
        const companyId = CompanyObserver.companyId;
        if(!companyId) return;
        const checklistId = allChecklist.find((item) => item.isSelected)?.id;
        if(!checklistId) return;

        const params = paramsFactory();
        if(!params) return;
        params.delete('pageSize');
        params.delete('pageToken');
        if(!params.get('orderByRequest')) {
            params.delete('orderByRequest')
        }

        axiosInstance.get(UrlCreator.getAllCallsAnalyticsFile(companyId, checklistId),{
            params: params,
            headers: {
                accept: 'text/csv',
            }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            const fileName = `downloaded Report ${dayjs(new Date()).format("DD MMM YY")}.csv`;
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <>
            {isLoading ? <Loader/> : null}
            {isOpenOperatorsModal ? (
                <ModalFilterOperator
                    handleClose={handleCloseFilterModal}
                    handleSetOperator={handleSetOperators}
                    selectedOperatorsJSON={searchParams.get('operator')}
                    handleClearOperators={handleClearOperators}
                />
            ) : null}
            <BaseLayout title={'Аналітика'}>
                <div className={styles.automatic__header}>
                    <div className={styles.automatic__dates}>
                        {initialDateFilters.map((item: IDateFilter) => {
                            if (item.type === EnumDateFastFilterType.CUSTOM_RANGE) return null;
                            return (
                                <DateFastFilter
                                    key={item.type}
                                    type={item.type}
                                    isActive={String(item.type) === getSearchParams('dateFilterType')}
                                    handleSetDate={handleSelectFastDateFilter}
                                />
                            )
                        })}
                        <CustomDatePicker
                            startDate={getSearchParamsCustomRangeDate('startDate')}
                            endDate={getSearchParamsCustomRangeDate('endDate')}
                            handleSelectDate={handleSelectDate}
                        />
                        <div className={styles.automatic__total}>
                            Загальна кількість: {total}
                        </div>
                    </div>
                    <div className={styles.automatic__header_left}>
                        <InputSearch
                            value={getSearchParams('search') ? String(getSearchParams('search')) : ''}
                            onChange={handleChange}
                        />
                        <LoadCallsFile onClick={handleLoadCSV}/>
                    </div>
                </div>
                {getTags().length ? (
                    <div className={styles.automatic__tags}>
                        {getTags().map((item) => (
                            <FilterTag
                                key={item.title}
                                type={item.type}
                                title={item.title}
                                handleDelete={handleClearTag}
                            />
                        ))}
                        <ClearAllTagsBtn onClick={handleClearAllTags} />
                    </div>
                ) : null}
                <div className={styles.automatic__subheader}>
                    <div/>
                    <ChecklistSwitch setChecklists={setAllChecklist} checklists={allChecklist}/>
                    <div/>
                </div>
                <ChecklistTable
                    ref={tableRef}
                    data={checklist}
                    selectedSort={getSearchParams('sort') as EnumSortType}
                    handleSelectSort={handleSelectSort}
                    handleOpenFilterModal={handleOpenFilterModal}
                    filter={getSearchParams('operator') as string}
                />
                <div className={styles.automatic__footer}>
                    <div/>
                    <CustomPagination
                        total={getSearchParams('topProblems') ? Number(getSearchParams('topProblems')) : total}
                        perPage={Number(getSearchParams('perPage'))}
                        page={Number(getSearchParams('page'))}
                        setPage={handleSetPage}
                    />
                    {!getSearchParams('topProblems') ? (
                        <PerPageDropDown
                            setPerPage={handleSetSearchParams}
                            perPage={Number(getSearchParams('perPage'))}
                        />
                    ) : null}
                </div>
            </BaseLayout>
        </>
    );
};

export default observer(ChecklistPage);
